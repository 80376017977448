import {
	format,
	formatDistanceToNow,
	parseISO,
	isValid,
	Locale,
} from "date-fns";
import { enUS, es } from "date-fns/locale";
import Pusher, { Channel } from "pusher-js";
import { NotificationCallback, NotificationData } from "./types";

/**
 * PusherNotification class
 *
 * This class handles receiving notifications via Pusher.
 */
class PusherNotification {
	private pusher: Pusher;
	private channel: Channel;
	private locale: Locale;
	private notificationCallback: NotificationCallback;

	/**
	 * Constructor
	 *
	 * @param appKey - Pusher app key
	 * @param cluster - Pusher cluster
	 * @param channelName - Name of the channel to subscribe to
	 * @param notificationCallback - Callback function to handle incoming notifications
	 */
	constructor(
		notificationCallback: NotificationCallback,
		channelName: string,
		appKey: string = import.meta.env.VITE_PUSHER_APP_KEY || "",
		cluster: string = import.meta.env.VITE_PUSHER_APP_CLUSTER || "mt1"
	) {
		this.pusher = new Pusher(appKey, {
			cluster: cluster,
		});
		this.channel = this.pusher.subscribe(channelName);
		this.notificationCallback = notificationCallback;
		this.locale = this.detectLocale();
		this.channel.bind("notification", (data: NotificationData) => {
			this.notificationCallback(data);
		});
	}

	/**
	 * Detect the locale from the browser or document
	 *
	 * @returns Locale object
	 */
	private detectLocale(): Locale {
		const lang =
			document.documentElement.lang || navigator.language || "en";
		return lang.startsWith("es") || lang.startsWith("ec") ? es : enUS;
	}

	/**
	 * Update the channel subscription
	 *
	 * @param channelName - New channel name to subscribe to
	 */
	public updateChannel(channelName: string): void {
		this.pusher.unsubscribe(this.channel.name);
		this.channel = this.pusher.subscribe(channelName);

		this.channel.bind("notification", (data: NotificationData) => {
			this.notificationCallback(data);
		});
	}

	/**
	 * Update the Pusher instance
	 *
	 * @param appKey - New Pusher app key
	 * @param cluster - New Pusher cluster
	 * @param channelName - New channel name to subscribe to
	 */
	public updatePusherInstance(
		appKey: string,
		cluster: string,
		channelName: string
	): void {
		this.pusher.disconnect();
		this.pusher = new Pusher(appKey, {
			cluster: cluster,
		});
		this.updateChannel(channelName);
	}

	/**
	 * Format the date to a human-readable format
	 *
	 * @param date - Date string to format
	 * @returns Formatted date string
	 */
	public formatDate(date: string): string {
		const notificationDate = parseISO(date);
		if (!isValid(notificationDate)) {
			return ""; // Return empty string if the date is invalid
		}

		const now = new Date();
		const differenceInHours =
			(now.getTime() - notificationDate.getTime()) / (1000 * 60 * 60);

		if (differenceInHours < 2) {
			return formatDistanceToNow(notificationDate, {
				addSuffix: true,
				locale: this.locale,
			});
		}
		return format(notificationDate, "d MMM yyyy, HH:mm", {
			locale: this.locale,
		});
	}
}

export default PusherNotification;
export type { NotificationData };
